<template>
  <div>
    <div class="row">
      <div class="col">
        <h4 class="text-center text-uppercase">
          REPORTE FINANCIERO - {{ entity.name }} {{ dateVerbose }}
        </h4>
      </div>
    </div>
    <div class="row bg-white d-print-none">
      <div class="col mt-3">
        <div class="form-group form-inline">
          <app-ctrl-year-month v-model="date"></app-ctrl-year-month>
          <!-- <app-select-ym
            :initiated="true"
            v-model="date"
            ref="sym"
          ></app-select-ym> -->
        </div>
      </div>
    </div>
    <div class="row bg-white">
      <div class="col-sm-6">
        <h5>VISTA GENERAL</h5>
        <table class="table table-sm">
          <thead>
            <tr>
              <th>CONCEPTO</th>
              <th class="text-right">MONTO</th>
              <th class="text-right">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(mc, index) in kardex">
              <template v-for="(l, i) in mc">
                <tr :key="index + i" :class="{ 'text-primary': i == 0 }">
                  <template v-if="l.s">
                    <th>{{ l.title }}</th>
                    <th class="text-right">
                      <app-span-money
                        :quantity="l.quantity"
                        :moneyCode="l.money_code"
                      ></app-span-money>
                    </th>
                    <th class="text-right">% {{ l.prc }}</th>
                  </template>
                  <template v-else>
                    <td>{{ l.title }}</td>
                    <td class="text-right">
                      <app-span-money
                        :quantity="l.quantity"
                        :moneyCode="l.money_code"
                      ></app-span-money>
                    </td>
                    <td class="text-right">% {{ l.prc }}</td>
                  </template>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
        <h5>DETALLE DE INGRESOS</h5>
        <table class="table table-sm">
          <thead>
            <tr>
              <th>FUENTE</th>
              <th class="text-right">MONTO</th>
              <th class="text-right">%</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(l, index) in inputs_by_categories">
              <tr :key="index" v-show="l.quantity > 0">
                <td>
                  <span>{{ l.categorie_name }}</span>
                  <app-progressbar
                    :value="(l.quantity * 100) / input_by_money[l.money_code]"
                  ></app-progressbar>
                </td>
                <td class="text-right">
                  <app-span-money
                    :quantity="l.quantity"
                    :moneyCode="l.money_code"
                  ></app-span-money>
                </td>
                <td class="text-right">
                  {{ (l.quantity / input_by_money[l.money_code]) | percent(2) }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <h5>INGRESOS POR MEDIO DE PAGO</h5>
        <table class="table table-sm">
          <tr v-for="(in_pm, index) in rep.inputs" :key="index">
            <th>{{ paymentMethods[in_pm.payment_method_id].value }}</th>
            <th class="text-right">
              <app-span-money
                :quantity="in_pm.quantity"
                :moneyCode="in_pm.money_code"
              ></app-span-money>
            </th>
          </tr>
        </table>
      </div>
      <div class="col-sm-6">
        <h5>DETALLES DE GASTOS</h5>
        <table class="table table-sm table-responsive">
          <thead>
            <tr>
              <th>TIPO DE GASTO</th>
              <th>RAZON DE EGRESO</th>
              <th class="text-right">MONTO</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(l, i) in rep.output_details" :key="i">
              <template v-if="!l.total">
                <td>
                  {{ reasonTypes[l.type_id] && reasonTypes[l.type_id].name }}
                </td>
                <td>{{ l.reason_name }}</td>
                <td class="text-right">
                  <app-span-money
                    :quantity="l.quantity"
                    :moneyCode="l.money_code"
                  ></app-span-money>
                </td>
              </template>
              <template v-else>
                <th colspan="2">{{ l.reason_name }}</th>
                <th class="text-right">
                  <app-span-money
                    :quantity="l.quantity"
                    :moneyCode="l.money_code"
                  ></app-span-money>
                </th>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { AdminService } from "../AdminService";
import { mapState } from "vuex";
import { myMoment } from "../../utils/myMoment";

export default {
  data() {
    return {
      date: {},
      rep: {},
      kardex: {},
      inputs_by_sources: [],
      inputs_by_categories: [],
      input_by_money: {},
      entity: {}
    };
  },
  watch: {
    // date: {
    //   immediate: true,
    //   handler() {
    //     this.getList();
    //   }
    // }
    date() {
      this.getList();
    }
  },
  computed: {
    ...mapState({
      reasonTypes: (s) => s.config.admin.reasons.types,
      paymentMethods: (s) => s.config.payment_method.list,
      user: (s) => s.user
    }),

    dateVerbose() {
      return myMoment(`${this.date.year}-${this.date.month}`, "YYYY-MM").format(
        "MMMM YYYY"
      );
    }
    // reasonTypes() {
    //   return this.$store.state.config.admin
    //     ? this.$store.state.config.admin.reasons.types
    //     : [];
    // },
    // paymentMethods() {
    //   return this.$store.state.config.payment_method
    //     ? this.$store.state.config.payment_method.list
    //     : [];
    // }
  },
  mounted() {
    // this.$refs.sym.setDefault();
    // setTimeout(() => {
    //   this.getList();
    // }, 300)  ;
  },
  methods: {
    reset() {
      this.input_by_money = {};
    },
    getList() {
      AdminService.getEntity(this.user.entity_id).then((ent) => {
        this.entity = ent;
      });

      AdminService.getReportFinantial(this.date).then((res) => {
        this.reset();

        this.inputs_by_sources = res.inputs_by_sources;
        this.inputs_by_categories = res.inputs_by_categories;
        res.inputs.map((x) => {
          if (!this.input_by_money[x.money_code])
            this.input_by_money[x.money_code] = 0;
          this.input_by_money[x.money_code] += parseFloat(x.quantity);
        });

        this.rep = res;
        this.kardex = {};
        for (const { money_code } of this.rep.inputs) {
          this.kardex[money_code] = this.rep.kardex.filter((x) => {
            return x.money_code == money_code;
          });
        }

        let ls_type = {
          type_id:
            this.rep.output_details.length > 0
              ? this.rep.output_details[0].type_id
              : null,
          money_code:
            this.rep.output_details.length > 0
              ? this.rep.output_details[0].money_code
              : null,
          quantity: 0
        };
        for (let i = 0; i < this.rep.output_details.length; i++) {
          let x = this.rep.output_details[i];
          if (
            x.type_id != ls_type.type_id ||
            x.money_code != ls_type.money_code
          ) {
            this.rep.output_details.splice(i, 0, {
              type_id: ls_type.type_id,
              quantity: ls_type.quantity,
              money_code: ls_type.money_code,
              reason_name: "TOTAL",
              total: true
            });
            ls_type = {
              type_id: x.type_id,
              quantity: 0,
              money_code: x.money_code
            };
          } else {
            ls_type.quantity += parseFloat(x.quantity);
          }
        }
        if (this.rep.output_details.length > 0) {
          this.rep.output_details.push({
            type_id: ls_type.type_id,
            quantity: ls_type.quantity,
            money_code: ls_type.money_code,
            reason_name: "TOTAL",
            total: true
          });
        }
      });
    }
  }
};
</script>

<style scoped></style>
